@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap");

@font-face {
  font-family: "SummerRegular";
  font-weight: lighter;
  src: url(assets/font/summer/SummerLight.otf);
}

@font-face {
  font-family: "SummerRegular";
  font-weight: normal;
  src: url(assets/font/summer/SummerRegular.otf);
}

@font-face {
  font-family: "SummerBold";
  font-weight: bold;
  src: url(assets/font/summer/SummerBold.otf);
}

@font-face {
  font-family: "MontserratRegular";
  font-weight: normal;
  src: url(assets/font/montserrat/MontserratRegular.otf);
}

@font-face {
  font-family: "MontserratMedium";
  src: url(assets/font/montserrat/MontserratMedium.otf);
}

@font-face {
  font-family: "MontserratBold";
  src: url(assets/font/montserrat/MontserratBold.otf);
}

@font-face {
  font-family: "MontserratSemiBold";
  src: url(assets/font/montserrat/MontserratSemiBold.otf);
}

@font-face {
  font-family: "MontserratLight";
  src: url(assets/font/montserrat/MontserratLight.otf);
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  opacity: 0;
}

::-webkit-scrollbar-thumb {
  background: #888;
  opacity: 0;
  transition: 0.2s;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
  opacity: 1;
}

.w-20 {
  width: 20% !important;
}

.w-80 {
  width: 80% !important;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-50 {
  margin-left: 50px;
}

/* body .table>:not(caption)>*>* {
    padding: 0.7rem 0.5rem;
} */

/* body textarea::-webkit-scrollbar,body select::-webkit-scrollbar {
    width: 8px;
}

body textarea::-webkit-scrollbar-track,body select::-webkit-scrollbar-track {
    background: #f1f1f1;
    opacity: 0;
    border-radius: 10px;
}

body textarea::-webkit-scrollbar-thumb,body select::-webkit-scrollbar-thumb {
    background: #888;
    opacity: 0;
    border-radius: 10px;
}

body textarea::-webkit-scrollbar-thumb:hover,body select::-webkit-scrollbar-thumb:hover {
    background: #555;
    opacity: 1;
} */

input:focus-visible {
  outline: none;
}

.topnav a:hover {
  background-color: rgba(0, 0, 0, 0.08);
  /* color: inherit; */
}

.topnav .active {
  color: #ee8738;
  /* background-color: rgba(0, 0, 0, 0.08); */
}

/* login page css starts */

/* login form animation starts */

.form {
  width: 100%;
  height: 30px;
  position: relative;
  margin: 20px 0;
}

/* draggable table css */

.headerDrag {
  padding: 20px;
  margin-left: 20px;
  margin-top: 20px;
  /* width: 50%; */
  width: 80%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  color: #dd2c00;
  background: #f8d5bc !important;
  border-radius: 10px;

  /* padding: 15px 20px; */
  border: 1px solid #fa6000;
  border-radius: 10px;
}

.contentDrag .name {
  padding: 0 56px 0 0;
}

.contentDrag {
  margin-left: 20px;
  width: 80%;
}

/* .dragTable{

} */
.dragTable .table tbody tr td:first-child {
  padding: 0 24px !important;
  width: 10% !important;
  color: rgb(74, 74, 74) !important;

  /* font-weight: 600; */
}

.textStyle {
  text-align: inherit !important;
  font-size: larger;
  /* color: #0e0d0d!important; */
  font-weight: 700;
  /* padding: 0 24px; */
}

/* end draggable */
.form input {
  width: 100%;
  height: 100%;
  color: #000000;
  padding-top: 0px;
  border: none;
  outline: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(0);
  }
}

.menu {
  animation: fadeIn 0.3s ease-in-out;
  width: 100% !important;
}

.menu--close {
  animation: fadeOut 0.3s ease-in-out;
}

@keyframes fadeInFilter {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutFilter {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(0);
  }
}

.menuFilter {
  animation: fadeInFilter 0.3s ease-in-out;
  width: 100% !important;
}

.menu--closeFilter {
  animation: fadeOutFilter 0.3s ease-in-out;
}

div#react-select-3-listbox {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
}

body .btn-close:focus {
  outline: 0;
  box-shadow: none;
  color: #ee8738;
}

/* #myModaldelete .title_ing {
    color: #ff0018 !important;
} */

body .modal-body {
  padding: 1rem 1.5rem;
}

.font-12 {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
}

.new_recipe_page .form_card .form label {
  font-size: 20px;
}

.color_orange {
  color: #ee8738;
}

.form_card .form label {
  position: absolute;
  width: 100%;
  height: 30px;
  bottom: 0px;
  left: 0%;
  font-size: 20px;
  pointer-events: none;
  color: rgba(0, 0, 0, 0.54) !important;
  border-bottom: 1px solid #eb6c19 !important;
}

.form label::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  border-bottom: 2px solid #1cbd69;
  bottom: 0px;
  left: 0px;
  transition: all 0.3s ease;
}

.content-name {
  position: absolute;
  bottom: 4px;
  left: 0px;
  transition: all 0.3s ease;
  font-size: 14px;
  color: #1cbd69;
}

.form input:focus + .label-name .content-name {
  transform: translateY(-88%);
  color: #fa6d32;
}

.form input:focus + .label-name::after {
  transform: translateX(0%);
}

.form input.lblup + .label-name .content-name {
  transform: translateY(-88%);
}

.form input:focus + .label-name .content-name,
.form input:valid + .label-name .content-name {
  font-size: 14px;
  pointer-events: none;
}

.form input:focus + .label-name::after,
.form input:valid + .label-name::after {
  width: 100%;
}

.form textarea:focus + .label-name .content-name {
  transform: translateY(0%);
  font-size: 12px;
}

.form input,
.form textarea {
  padding-right: 60px;
}

.form textarea:focus + .label-name::after {
  transform: translateX(0%);
}

.text_bold {
  font-weight: 500 !important;
  font-family: "MontserratRegular";
}

.select_caret_adjust select.form-select {
  width: 80%;
}

.form textarea {
  width: 100%;
  background: none;
  border: 0;
}

.form input.error + label::after {
  border-bottom: 2px solid #fa6d32;
  color: #fa6d32 !important;
}

.form input.error + label span {
  color: #1cbd69;
}

.forgot_success_txt,
.incorrect_info_msg,
.whats_email {
  color: #f80544;
  font-size: 14px;
  width: 75%;
  margin: 20px auto 10px;
  font-weight: 600;
}

.correct_info_msg {
  color: #13bd72;
  font-size: 14px;
  width: 75%;
  margin: 20px auto 10px;
  font-weight: 600;
}

.forgot_success_txt {
  color: #6c6c6c;
  font-size: 15px;
  width: 100%;
  text-align: center;
}

.ing-success {
  width: 40px !important;
  margin-right: 15px;
}

.titleLabel {
  color: #fff;
  font-size: 42px;
  font-family: "SummerRegular";
  font-weight: normal;
}

.input_disabled {
  pointer-events: none;
  color: #aaa;
}

.label_disabled {
  pointer-events: none;
}

/* login form animation ends */

.login_page_adjust {
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e0e0e0;
  border-color: #e0e0e0;
  color: #000000de;
  overflow: hidden;
}

.login_page_adjust > p {
  background: #ff0000;
  text-align: center;
  color: #ffffff;
  max-width: 500px;
  margin: 0 auto 30px;
  width: 500px;
  font-family: "SummerRegular";
  font-size: 36px;
  text-transform: uppercase;
  line-height: 45px;
}

.login_container {
  background: white;
  width: 500px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin: 0 15px;
  box-shadow: none;
}

.logo_login {
  width: 100%;
  padding: 25px;
  min-height: 170px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #fa6d32 !important;
  text-align: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.login_error {
  background-color: rgb(248, 5, 68) !important;
  color: #fff;
  font-family: "SummerBold";
  font-weight: bold;
  font-size: 20px;
}

.logo_login img {
  width: 210px;
  height: auto;
  object-fit: contain;
}

.logo_login form {
  padding: 0 15px;
}

.main_spacing .form-check:not(:last-child) {
  margin-bottom: 10px;
}

.login_container form {
  padding: 16px 60px;
  margin-top: 32px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.email_field {
  margin-top: 0px;
}

.email_field input,
.password_field input {
  border-bottom: 2px solid #1cbd69;
}

.password_field {
  margin-top: 26px;
  margin-bottom: 13px;
}

.forgot_login {
  margin: 16px 0 30px;
  text-align: center;
}

.forgot_login a {
  color: #1cbd69 !important;
  text-decoration: none;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
}

button.login_btn {
  width: 100%;
  border: none;
  border-radius: 50px;
  color: #fff !important;
  cursor: default;
  padding: 6px 30px !important;
  background-color: #13bd72 !important;
  margin-bottom: 20px !important;
  min-height: 36px;
  min-width: 88px;
  line-height: 36px;
  vertical-align: middle;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  font-style: inherit;
  font-variant: inherit;
  font-family: inherit;
  text-decoration: none;
  overflow: hidden;
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.instructions_counter.ng-binding {
  display: none;
}

.text_area > i.fa-regular,
.text_area > i.fa {
  position: absolute;
  right: -40px;
  top: 30px;
  color: #4a4a4a;
}

button.login_btn:hover {
  transition: 0.2s ease;
  background: #ffffff;
  color: #9fc849;
}

/* login page css ends */

/* Ingredients css starts */

.main_part {
  display: flex;
  margin-top: 64px;
  align-items: flex-start;
  height: calc(100vh - 64px);
  overflow: auto;
  background: rgb(250, 250, 250);
}

.left_bar {
  max-width: 260px;
  width: 100%;
  min-height: calc(100vh - 64px);
  border-right: none;
  position: sticky;
  top: 0;
  background-color: rgb(224, 224, 224);
  border-color: rgb(224, 224, 224);
  color: rgba(0, 0, 0, 0.87);
}

.main_logo img {
  max-width: 100px;
  width: 200px;
  height: 64px;
  margin-right: 12px;
  object-fit: contain;
  text-align: center;
}

.main_logo {
  text-align: center;
  background: rgb(250, 96, 0);
  box-shadow: none;
  max-width: 260px;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 3;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.main_logo a {
  display: inline-block;
}

/* li a span .category_select_icon {
    display: none;
} */

li.active a span .category_select_icon {
  display: block;
}

li.active a span .category_icon {
  display: none;
}

.left_bar ul li a {
  padding: 6px 16px;
}

.left_bar ul li {
  padding: 0;
  text-align: left;
  width: 100%;
  height: 60px;
  margin: 0;
  text-transform: none;
  border-radius: 0 !important;
  white-space: normal;
  line-height: 48px;
  position: relative;
  z-index: 2;
  box-shadow: none !important;
}

.left_bar ul {
  padding: 0;
  list-style-type: none;
  margin-top: 0px;
}

.left_bar ul a {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  color: #6c6c6c;
  text-decoration: none;
  font-size: 14px;
  letter-spacing: 0.1px;
}

.left_bar ul a span {
  width: 100%;
  display: inline-flex;
  align-items: center;
  font-family: "MontserratRegular";
}

.left_bar ul a i {
  font-size: 10px;
  min-height: 24px;
  min-width: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.left_bar ul li.active a {
  color: #ee8738 !important;
  font-weight: 500;
}

header {
  background: #ffffff;
  display: flex;
  align-items: center;
  height: 64px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
}

.user_live {
  width: 60%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}

.breadcrumb_name {
  background: #ff0000;
  text-align: center;
  color: #ffffff;
  max-width: 750px;
  width: 700px;
  font-family: "SummerRegular";
  font-size: 36px;
  text-transform: uppercase;
  line-height: 45px;
  margin: 0;
}

.right_header {
  display: flex;
  width: calc(100% - 260px);
  justify-content: space-between;
  border-bottom: none;
  align-items: center;
  padding: 10px 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

thead th {
  background: #fff !important;
  position: relative;
}

.headerLoader {
  top: 0px;
  width: 85%;
  position: absolute !important;
  z-index: 999 !important;
  height: 5px !important;
}

.modelPushLoader {
  top: 0px;
  right: 0px;
  width: 100%;
  position: absolute !important;
  z-index: 999 !important;
  height: 4px !important;
}

.modelLoader {
  top: 16px;
  width: 100%;
  position: fixed !important;
  z-index: 999 !important;
  height: 4px !important;
}

.tableLoader {
  top: 41px;
  width: 100%;
  position: absolute !important;
  z-index: 1 !important;
  height: 2px !important;
}

.loginLoader {
  top: 0;
  width: 100%;
  position: absolute !important;
  z-index: 999 !important;
  height: 4px !important;
}

.main_table table {
  box-shadow: none;
  margin-bottom: 0;
  background: #fff;
}

.right_bar
  .d-flex.align-items-end.userSearch.justify-space-between.add_search
  > button {
  margin-bottom: -8px;
}

.main_table table thead,
.main_table table tbody {
  width: 100%;
}

.text_area span.limit {
  right: 20px;
}

.main_table .table thead tr th {
  font-size: 14px;
  font-weight: 600 !important;
  white-space: nowrap;
}

.main_table .table thead tr th,
.main_table .table tbody tr td {
  outline: none !important;
  box-shadow: none !important;
}

.main_table .table thead tr th:first-child {
  padding: 0 24px;
  width: 69px;
  color: rgb(74, 74, 74) !important;
  font-weight: 700 !important;
  font-family: "MontserratSemiBold";
}

.main_table .table tbody tr td:first-child {
  padding: 0 24px;
  width: 69px;
  color: #4a4a4a !important;
  font-size: 14px !important;
  font-family: "MontserratRegular";
}

.main_table .table tbody tr td:first-child > div {
  font-weight: 500;
  /* width: calc(600px - 150px); */
}

.main_table .table tbody tr .recipe_with_image {
  width: calc(100% - 150px);
}

.main_table .table thead tr th:nth-child(2) {
  padding: 0 56px 0 0;
  width: auto;
  color: rgb(74, 74, 74) !important;
  font-weight: 700;
}

.main_table .table tbody tr td:nth-child(2) {
  padding: 0 56px 0 0;
  font-family: "MontserratLight";
  color: #4a4a4a !important;
}

.main_table .table thead tr th:last-child,
.main_table .table tbody tr td:last-child {
  padding: 0 24px 0 0;
  text-align: right;
}

.main_table .table thead tr {
  height: 40px;
}

.main_table .table tbody tr {
  height: 60px;
  background-color: #fff;
  font-size: 14px;
}

.main_table .table tbody tr:hover {
  background: #eee;
  cursor: pointer;
}

.ingredient_table .table tr:hover {
  background: #fff !important;
  cursor: unset !important;
}

.right_bar {
  width: 100%;
  background: rgb(250, 250, 250);
  min-height: calc(100vh - 71px);
  max-width: calc(100% - 260px);
  /* padding: 30px; */
}

.user_image img {
  border-radius: 50%;
  width: 30px;
  width: 30px;
  object-fit: contain;
}

.user_content {
  margin-left: 40px;
  display: flex;
  align-items: center;
  padding: 6px;
  border-radius: 2px;
  text-align: center;
  line-height: 36px;
  vertical-align: middle;
}

.user_content:hover {
  background-color: rgba(158, 158, 158, 0.2);
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
  transition-property: background-color, fill, color;
}

.main_title {
  text-align: left;
  font-family: "SummerBold";
  font-size: 36px;
  text-transform: uppercase;
  color: #6c6c6c;
  line-height: 45px;
  margin-bottom: 0;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
}

.user_dropdown button {
  font-size: 14px;
  font-weight: 600;
  padding-right: 0;
  border: 0;
}

.user_dropdown .dropdown-item img {
  object-fit: contain;
  height: 14px;
  width: auto;
  margin-right: 15px;
}

.user_dropdown .dropdown-menu {
  padding: 0;
  border-radius: 0;
}

.user_dropdown .dropdown-item {
  padding: 10px;
  margin: 10px 0px;
  background-color: var(--bs-dropdown-link-hover-bg);
}

body .btn-check:focus + .btn,
body .btn:focus {
  outline: 0;
  box-shadow: none;
}

.user_dropdown button.dropdown-toggle:after {
  display: none !important;
}

.user_dropdown .dropdown-menu {
  padding: 0;
  transform: none !important;
  top: -6px !important;
  bottom: inherit !important;
  right: 0px !important;
  left: inherit !important;
  width: 256px;
}

body .dropdown-item.active,
body .dropdown-item:active {
  color: #0c0c0c;
  text-decoration: none;
  background-color: #d9d9d9;
}

.left_bar ul li img {
  margin-right: 10px;
  object-fit: contain;
  width: 21px;
  height: 21px;
}

/* .left_bar ul li.active img {
    filter: sepia(1);
} */

.add_search {
  width: 100%;
  justify-content: space-between;
  padding: 30px !important;
}

.userSearch {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-family: RobotoDraft, Roboto, "Helvetica Neue", sans-serif !important;
}

button.add_button {
  background: #13bd72 !important;
  border: 1px solid #13bd72;
  padding: 5px 35px !important;
  border-radius: 50px;
  color: #fff !important;
  min-height: 36px;
  min-width: 130px;
  line-height: 36px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "MontserratMedium";
  font-size: 14px;
}

button.add_button i {
  margin-right: 10px;
}

button.done_main {
  background: #13bd72 !important;
  border: 1px solid #13bd72;
  border-radius: 2px;
  padding: 6px 35px !important;
  color: #fff !important;
  height: 36px !important;
  min-height: 26px;
  min-width: 88px;
  display: flex;
  align-items: center;
  line-height: 36px !important;
  font-size: 14px !important;
  text-transform: uppercase;
}

.user_page.add_search {
  justify-content: flex-end;
}

.user_search.add_search {
  justify-content: flex-start;
  padding: 0 30px;
}

.user_search .search_bar {
  justify-content: flex-start;
}

.user_card {
  display: flex;
  max-width: 100%;
  padding: 0px 30px;
  float: left;
  width: 50%;
}

.clearFix {
  clear: both;
}

.user_card > div {
  width: 50%;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #a5a5a5, 0 1px 1px 0 #a5a5a5, 0 2px 1px -1px #a5a5a5;
}

.user_card > div:first-child {
  margin-right: 15px;
}

.user_card h4 {
  font-weight: 500;
  color: #4a4a4a;
  font-size: 17px;
  line-height: 2.4rem;
}

.user_card > div .count {
  font-size: 26px;
  color: #4a4a4a;
  font-weight: 700;
}

button.add_button:hover {
  transition: 0.2s ease;
  background: white;
  color: #9fc849;
}

.publish_faq:hover {
  transition: 0.2s ease;
  background: white;
  color: #9fc849;
}

.search_bar img {
  height: 26px !important;
  height: auto;
  object-fit: contain;
  margin-right: 10px;
  width: auto;
}

.search_bar input {
  border: 0;
  height: 30px;
  border-bottom: 1px solid;
  background: none;
  max-width: 155px;
  width: 100%;
  font-weight: 500 !important;
}

.search_bar input::placeholder {
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.4) !important;
  font-family: RobotoDraft, Roboto, "Helvetica Neue", sans-serif;
}

.search_bar input {
  border: 0;
  border-bottom: 1px solid;
  border-color: rgba(0, 0, 0, 0.12);
  background: none;
  max-width: 70%;
  width: 100%;
}

.search_bar input:focus,
.ing_main_pop input:focus {
  max-width: none;
  outline: none !important;
  transition: all 0.2s cubic-bezier(0.4, 0, 1, 1);
  border-bottom: 2px solid #000;
  width: 100%;
  max-width: 100%;
}

.modal-header {
  border-bottom: 0;
}

.ing_content {
  text-align: center;
}

.title_ing {
  margin-top: 15px;
  color: #ee8738;
  font-family: "SummerRegular";
  font-size: 36px;
  text-transform: uppercase;
  line-height: 45px;
}

.add_ing .modal-dialog {
  max-width: 800px;
  margin: 25px auto;
  min-height: calc(100vh - 71px);
  display: flex;
  align-items: center;
}

.myModaldelete .modal-dialog {
  max-width: 450px !important;
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.modal-content {
  border-radius: 4px !important;
}

.add_ing .modal-content {
  border: 1px solid #ee8738;
  max-height: 80vh;
  overflow: auto;
}

.add_ing .modal-content .modal-header {
  background-color: #ffffff;
  position: sticky;
  top: 0px;
  left: 0;
  width: calc(100% - 2px);
  height: 52px;
  z-index: 9;
}

.add_ing .modal-content .modal-body {
  height: calc(100% - 52px);
  overflow: auto;
  padding-left: 40px;
  padding-right: 40px;
}

.add_ing .modal-content .modal-footer {
  background-color: #ffffff;
  position: sticky;
  bottom: 0px;
  left: 0;
  width: calc(100% - 2px);
  z-index: 9;
  border: none !important;
  padding: 40px 0 20px;
}

.add_ing .modal-content .modal-footer .btn_add_ing,
.add_ing .modal-content .modal-footer .btn_add_ing button {
  margin: 0 auto !important;
}

.modal-header button {
  color: #ee8738 !important;
}

.ing_main_pop {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 80px;
  /* margin: 25px auto
    width: 94%; */
}

.ingredient_input {
  position: relative;
  padding: 2px;
  margin: 25px 0;
}

.grey_text {
  color: #6c6c6c !important;
  margin: 13px 0 0;
  font-size: 15px;
  text-align: center;
}

.orange_text {
  color: #ee8738;
  margin: 13px 0 0;
  font-size: 15px;
  text-align: center;
}

.postnow .org_text {
  margin: 5px 0 !important;
}

.myModaldelete .org_text1 {
  margin-top: 25px !important;
}

.myModaldelete .org_text4 {
  margin-bottom: 30px !important;
}

.org_text {
  text-align: center;
  margin: 25px 0;
  color: #ee8738;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.005em;
  line-height: 1.5;
  font-family: "MontserratRegular";
}
.tag_org_text {
  text-align: center;
  margin: 10px 0;
  color: #ee8738;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.005em;
  line-height: 0;
  font-family: "MontserratRegular";
}

.org_text_error {
  text-align: center;
  /* margin: 25px 0; */
  color: #ee8738;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.005em;
  line-height: 1.5;
  font-family: "MontserratRegular";
}

.ing_main_pop_input {
  position: relative;
}

.ing_main_pop_input:not(:last-child) {
  margin-bottom: 25px;
}

.ing_main_popup_input input:focus,
.ing_main_popup_input select:focus,
.ing_main_popup_input select:focus-visible {
  border-color: #ee8738 !important;
  outline: none !important;
}

.left_main_pop .ingredient_input .ing_main_pop_input input::placeholder {
  color: rgba(0, 0, 0, 0.38);
}

.left_main_pop .ingredient_input .ing_main_pop_input input {
  height: 36px !important;
}

.ing_main_pop_input input,
.ing_main_pop_input select {
  width: 100%;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.38);
  font-family: "MontserratRegular";
  font-size: 15px;
  padding-right: 55px;
  outline: none !important;
}

.ing_main_pop_input input::placeholder {
  font-family: "MontserratRegular";
  color: rgba(0, 0, 0, 0.38);
  font-size: 14px;
}

.ing_main_popup_input.select .MuiInputLabel-formControl {
  font-family: "RobotoDraft", "Roboto", "Helvetica Neue", sans-serif;
}

.ing_main_popup_input.select {
  font-family: "RobotoDraft", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.38);
}

.search_bar input:focus {
  border-color: #333;
}

.ing_main_pop input:focus {
  border-color: #fa6000;
}

span.input-limit {
  /* input */
  font-weight: 500;
  font-size: 13px;
  color: #6c6c6c;
  position: absolute;
  right: 10px;
  bottom: 5px;
  font-family: MontserratRegular;
}

.new_recipe_page .text_area span.limit {
  right: 20px;
}

span.limit {
  /* textarea */
  font-weight: 500;
  font-size: 13px;
  color: #6c6c6c;
  position: absolute;
  right: 10px;
  bottom: 5px;
}

.form_card .text_area_main span.limit {
  right: 21%;
  bottom: 10px;
}

.postnow button.add_ing_button.neva {
  margin-bottom: 20px !important;
}

.postnow button.add_ing_button {
  width: 100% !important;
  margin: 0 !important;
  color: #fff !important;
  background-color: #13bd72 !important;
}

#myModaldelete button.add_ing_button {
  width: 90% !important;
  background: #13bd72 !important;
  border: 1px solid #13bd72;
  padding: 5px 28px;
  color: #fff !important;
  margin-bottom: 15px;
}

.modal.fade .modal-dialog {
  transform: scale(0.6) !important;
  transition: 0.2s !important;
}

.modal.show .modal-dialog {
  transform: scale(1) !important;
  transition: 0.2s !important;
}

.model_footer_text {
  margin: 20px 0;
  color: #ee8738;
  font-weight: 600;
  position: relative;
}

.model_footer_text span {
  background: #fff;
  position: relative;
  z-index: 9999;
  padding: 0 20px;
}

.model_footer_text:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  height: 1px;
  width: 100%;
  transform: translate(-50%, -50%);
  background-color: #ee8738;
}

.myModaldelete .myModaldelete_btn_width .add_ing_button.neva {
  background: #13bd72 !important;
  border: 1px solid #13bd72 !important;
  color: #fff !important;
}

#myModaldelete button.add_ing_button.neva {
  width: 90% !important;
  background: transparent !important;
  border: 1px solid #13bd72;
  padding: 5px 28px;
  color: #13bd72 !important;
  margin-bottom: 15px;
}

button.add_ing_button {
  width: 50% !important;
  border: none;
  border-radius: 50px;
  color: #fff !important;
  cursor: default;
  padding: 6px 30px !important;
  background-color: #13bd72 !important;
  margin-bottom: 20px !important;
  min-height: 36px;
  min-width: 88px;
  line-height: 36px;
  vertical-align: middle;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  font-style: inherit;
  font-variant: inherit;
  font-family: inherit;
  text-decoration: none;
  overflow: hidden;
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

button.add_ing_button:hover {
  transition: 0.2s ease;
  background: white;
  color: #13bd72;
}

.add_ing .btn_add_ing button.w-50 {
  width: 50% !important;
}

.ing_main_pop_input.select select {
  width: 100%;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.38);
  padding: 3px;
}

.modal_less_width .modal-dialog {
  max-width: 500px;
}

.action_icon img {
  height: 20px;
  width: auto;
  object-fit: contain;
}

.action_icon .fa-edit {
  transform: translatey(2px);
}

.main_table .react-bootstrap-table td select {
  border: none !important;
  background: none;
}

.main_table .react-bootstrap-table .editInput > input {
  padding-bottom: 5px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  color: #4a4a4a !important;
}

.editInput > input {
  border-bottom: 1px solid #fa6000 !important;
}

.editInput > input,
select {
  outline: none !important;
  border: 0;
  border-bottom: 1px solid #fa6000;
  width: 100%;
}

.ingSel:focus,
.ingSel:focus-visible {
  outline: none;
  background: #fff;
}

body input:focus {
  border-color: none;
  outline: 0;
  box-shadow: none !important;
}

.main_table.recipe_adjust table.table thead tr:hover {
  background-color: #eee;
}

.main_table.recipe_adjust table.table thead tr th:first-child,
.main_table.recipe_adjust table.table tbody tr td:first-child {
  width: 10%;
  max-width: 10%;
}

.main_table.recipe_adjust table.table thead tr th:nth-child(2),
.main_table.recipe_adjust table.table tbody tr td:nth-child(2) {
  width: 40%;
  max-width: 40%;
}

.main_table.recipe_adjust table.table thead tr th:nth-child(3),
.main_table.recipe_adjust table.table tbody tr td:nth-child(3) {
  width: 15%;
  max-width: 15%;
}

.main_table.recipe_adjust table.table thead tr th:nth-child(4),
.main_table.recipe_adjust table.table tbody tr td:nth-child(4) {
  width: 25%;
  max-width: 25%;
}

.main_table.recipe_adjust table.table thead tr th:last-child,
.main_table.recipe_adjust table.table tbody tr td:last-child {
  width: 10%;
  max-width: 10%;
}

.recipe_with_image {
  font-weight: 500;
  display: flex;
  align-items: center;
}

.recipe_with_image img {
  margin-top: 10px;
  margin-bottom: 10px;
}

.new_recipe_page .select_caret_adjust.sec_qty select {
  width: 100% !important;
}

.spinner_color {
  color: rgb(250, 96, 0);
}

.category_select_box .form-select {
  color: rgba(0, 0, 0, 0.5);
}

.category_select_box .form-select:focus {
  color: #000;
}

.input_drop .select_caret_adjust select {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.serving_select .select_caret_adjust {
  width: 75px !important;
}

.serving_select .select_caret_adjust select {
  padding: 3px 0;
  width: 100% !important;
}

.MuiPickersPopper-root .Mui-selected {
  background-color: #ee8738 !important;
}

.MuiPickersPopper-root .MuiPickersLayout-contentWrapper {
  border: 1px solid #000;
}

.select_caret_adjust select {
  border: 0;
  background-color: #f3f3f3;
  border-bottom: 1px solid;
  border-radius: 0;
}

.select_caret_adjust select.error {
  border: 0;
  background-color: #f3f3f3;
  border-bottom: 1px solid #dd2c00 !important;
  border-radius: 0;
}

.selectBoxClear {
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;
}

body select.form-select:focus {
  border-color: #eb6c19;
  outline: 0;
  box-shadow: none;
}

.form-check-input {
  border-color: rgba(0, 0, 0, 0.54) !important;
}

body .checkbox_container .form-check-input:checked {
  background-color: #ee8738 !important;
  border-color: #ee8738 !important;
}

body .form-check-input:checked {
  background-color: #fecd34 !important;
  border-color: #fecd34 !important;
}

.new_rec_view_all {
  padding: 15px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.post_now_adjust button {
  display: flex;
  align-items: center;
}

.label_main.sub {
  color: black;
}

.action_icons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 175px;
}

/* .action_icon:first-child {
  margin-right: 20px;
} */
.action_icon:last-child {
  margin-left: 20px;
}

.subscriber_action_icon:not(first-child) {
  margin-left: 20px;
}

.editDone {
  /* font-family: MontserratMedium; */
  background: #13bd72 !important;
  border: 1px solid #13bd72;
  padding: 2px 20px !important;
  border-radius: 50px;
  color: #fff !important;
  min-height: 36px;
  min-width: 88px;
  line-height: 36px;
  text-transform: uppercase;
  font-weight: 500;
  font-style: inherit;
  font-variant: inherit;
}

.main_table {
  margin: 0 30px 30px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);
}

body .table > :not(caption) > * > * {
  color: #565656;
  vertical-align: middle;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: #dee2e6;
}

body .table > :not(caption) > * > * {
  padding: 0.7rem 0.5rem;
}

tr:nth-child(odd) {
  background: #faffee;
}

.paginate {
  height: 50px;
  display: flex;
  justify-content: flex-end;
  background: white;
  box-sizing: border-box;
  padding: 0 24px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  border-top: 1px rgba(0, 0, 0, 0.12) solid;
}

.pagenum_content {
  display: flex;
  align-items: center;
}

.page_arrows {
  display: flex;
  align-items: center;
  margin-right: -16px;
}

.page_arrows .arrow_page {
  height: 40px;
  min-width: 0;
  line-height: 24px;
  padding: 8px;
  width: 40px;
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.38);
}

.page_arrows .arrow_page,
.page_arrows .arrow_page button {
  color: rgba(0, 0, 0, 0.38);
}

.pagenum_content .dropdown button {
  font-size: 14px !important;
}

.page_arrows img {
  height: 24px;
}

.pagenum_content {
  display: flex;
  align-items: center;
  grid-gap: 10px;
}

.pagenate_show {
  min-width: 120px;
}

.nodata {
  margin: 0 30px 30px;
  text-align: center;
  border-radius: 2px;
  padding: 70px !important;
  box-sizing: border-box;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);
}

.noDataImg img {
  margin-bottom: 10px !important;
  max-width: 100%;
}

.noDataText {
  font-size: 36px;
  color: #ee8738;
  text-transform: uppercase;
  font-family: "SummerRegular";
  letter-spacing: 0.01em;
}

.main_table th img {
  height: 17px;
  margin-left: 5px;
  position: absolute;
  top: 12px;
}

.btn_add_ing {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}

.modal {
  padding: 10px;
}

.btn_add_ing button {
  width: 80% !important;
  margin-left: auto;
  margin-right: auto;
  padding: 10px !important;
}

button.add_ing_button.neva {
  transition: 0.2s ease !important;
  background: white !important;
  color: #13bd72 !important;
  border: 1px solid #13bd72 !important;
}

/* button.add_ing_button.neva:hover {
    transition: 0.2s ease !important;
    background: #13bd72 !important;
    color: #fff !important;
} */

.action_icon {
  display: initial;
  cursor: pointer;
}

.myModalcommondelete .dec_text1 {
  font-size: 15px !important;
  color: #6c6c6c !important;
  text-align: center !important;
  margin: 30px 0 !important;
}

.myModalcommondelete .dec_text2,
.myModalcommondelete .dec_text3 {
  width: 100%;
  float: left;
  height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;
  color: #ee8738;
  text-align: center;
}

.myModalcommondelete .dec_text3 {
  margin-bottom: 20px;
}

.myModalcommondelete .dec_text4 {
  color: #6c6c6c !important;
  margin-top: 20px !important;
  font-size: 12px !important;
  line-height: 18px !important;
  font-weight: 400 !important;
  text-align: center !important;
}

a {
  cursor: pointer;
}

.only_search {
  width: 50%;
  padding: 18px 0px 4px;
  justify-content: flex-end;
}
.user_search {
  width: 50%;
  padding: 0px 0px 20px 10px;
  justify-content: flex-end;
}

.recipe_with_image img {
  width: 130px;
  height: auto;
  object-fit: contain;
  margin-right: 10px;
}

.new_recipe_page .recipe_input {
  margin-bottom: 40px !important;
}

.cancel_btn {
  margin: 30px;
  font-weight: 600;
  color: #6c6c6c;
  display: inline-flex;
  align-items: center;
  line-height: 20px;
  font-size: 15px;
  font-family: "MontserratMedium";
}

.cancel_btn img {
  width: auto;
  height: 24px;
}

.form input::placeholder {
  opacity: 0;
}

.form input:focus::placeholder {
  opacity: 1;
}

.cancel_btn a {
  color: black;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
}

.tabs_main {
  width: 100%;
  border: 1px solid #fa6000;
  border-radius: 10px;
  /* overflow: hidden; */
}

.tabs_main .tab_heading {
  color: #dd2c00;
  background: #f8d5bc !important;
  border-radius: 10px;
}

.tabs_main.open .tab_heading {
  color: #fff;
  background: #ee8738 !important;
  border-radius: 8px 8px 0 0;
}

.tab_heading {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background: #fa6000;
  color: #fff;
  padding: 15px 20px;
  border-radius: 0;
  cursor: pointer;
  font-size: 14px;
  font-family: "MontserratMedium";
}

.tab_heading p {
  margin: 0;
}

.tab_details {
  width: 80%;
  padding: 30px;
}

.select_main {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #fa6000;
  padding-bottom: 5px;
  margin-bottom: 30px;
  border-bottom: 1px solid #ee8738;
}

.select_fields_number {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #fa6000;
  color: #fff;
  font-size: 14px;
}

.select_fields_number p {
  margin: 0;
}

.select_fields_blog {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.select_position i {
  margin-left: 5px;
}

.select_fields_blog .select_caret_adjust.third_qty {
  flex: inherit !important;
  width: 60%;
  font-size: 14px;
}

.select_date {
  color: rgba(0, 0, 0, 0.45);
  font-size: 22px;
  position: relative;
}

.select_category {
  margin-top: 30px;
}

.not_select_field p {
  margin-bottom: 0;
}

.addzoom_url .main_title {
  text-align: center;
  color: #ee8738;
  font-weight: 100;
  font-family: "SummerRegular";
  -webkit-font-smoothing: antialiased;
}

.add_zoom_details .react-datepicker-wrapper {
  width: 75%;
}

.zoomlbl {
  font-size: 14px;
  font-family: RobotoDraft, Roboto, "Helvetica Neue", sans-serif;
}

.not_select_field {
  width: 100%;
  padding: 25px;
  float: left;
  background-color: #eee;
  border-radius: 3px;
  color: #909090;
  font-size: 14px;
  text-align: center;
  font-family: "MontserratMedium";
  -webkit-font-smoothing: antialiased;
}

.add_zoom_details {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
}

.add_zoom_details p {
  width: 20%;
  margin: 0;
  /* font-weight: 600; */
  color: #eb6c19;
  font-size: 14px;
  font-family: "MontserratMedium";
  text-transform: uppercase;
}

.add_zoom_details.add_recordCall_details {
  flex-direction: column;
  margin: 40px 0 25px;
}

.add_recordCall_details p {
  width: 75%;
  margin-bottom: 12px;
  /* margin-bottom: 8px; */
}

.add_zoom_details input {
  padding-bottom: 5px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.zoom_time_zone {
  background: #fff;
}

.popup_button {
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.popup_button button {
  width: 80%;
  border: none;
  outline: none;
  color: #fff;
  padding: 6px 0;
  background-color: #13bd72;
}

.add_zoom_details select {
  display: inline-block;
  width: auto;
}

.select_main p {
  color: #ee8738;
  font-size: 14px;
  margin: 0 !important;
  /* font-family: 'RobotoDraft', 'Roboto', 'Helvetica Neue', sans-serif; */
  font-family: "MontserratMedium";
  -webkit-font-smoothing: antialiased;
}

.add_zoom_details input[type="text"] {
  width: 60%;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.main {
  padding: 20px;
  border: 1px solid #000;
  min-width: 250px;
  max-width: 325px;
  font-family: RobotoDraft, Roboto, "Helvetica Neue", sans-serif;
  cursor: pointer;
}
.main-meal-plan {
  padding: 20px;
  border: 1px solid #000;
  min-width: 250px;
  max-width: 400px;
  font-family: RobotoDraft, Roboto, "Helvetica Neue", sans-serif;
  cursor: pointer;
  position: relative;
}
.meal-plan-icon {
  position: absolute;
  right: 20px;
  top: 20px;
}
.main-title {
  font-weight: 600;
  margin-bottom: 5px;
}

.main-left .main-date,
.main-left .main-date-desc {
  font-size: 14px;
}

.main-right {
  -ms-flex-direction: column;
  flex-direction: column;
}

.main,
.main-right {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.main-right span {
  text-decoration: underline;
  color: #000;
}

.main-right-text {
  text-align: right;
}

.challenge_table_btn .action_icon {
  margin: 10px !important;
}

.challenge_table_btn {
  display: inline-flex;
  flex-direction: column;
  /* align-items: center; */
}

.recipe_listing_img_bg {
  display: flex;
  align-items: center;
}

.recipe_listing_img_bg > img {
  width: 100px;
  height: 100px;
  float: left;
  overflow: hidden;
  margin: 10px 20px 10px 0;
  object-fit: cover;
}

div.recipe_listing_img_bg img.recipe_list_image {
  object-fit: cover;
  max-height: 100%;
  aspect-ratio: 1/1;
}

.recipe_calendar_icon_list {
  width: 18px !important;
  height: 16px !important;
  border-radius: 0 !important;
  margin: 0 8px 0 0 !important;
}

.lblScheduleList {
  color: #6c6c6c;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.01em;
  font-family: "MontserratRegular";
}

.justify-content-normal {
  justify-content: normal !important;
}

/* .b-0 .limit {
    bottom: 0;
} */

.bg_remove {
  background: none;
}

.cancel_btn a:hover {
  transition: 0.2s ease;
  color: #ff4155;
}

.main_spacing {
  padding: 0 25px;
}

.label-name {
  font-weight: 500;
}

body .checkbox_container .form-check-input:checked {
  background-color: #ee8738 !important;
  border-color: #ee8738 !important;
}

body .form-check-input:checked {
  background-color: #fecd34;
  border-color: #fecd34;
}

.label_main {
  font-weight: 500;
  margin-bottom: 10px !important;
  color: #eb6c19;
  font-size: 14px;
  text-transform: uppercase;
  margin: 0 !important;
  display: inline-flex;
  flex-direction: column;
  font-family: "MontserratMedium";
}

.label_main span {
  color: #6c6c6c;
  font-size: 12px;
  text-transform: lowercase;
  font-family: "MontserratRegular";
}
.label_main p {
  color: #6c6c6c;
  font-size: 12px;
  text-transform: none;
  font-family: "MontserratRegular";
}

.recipes_tags_main button {
  background: transparent;
}

.tags_main button {
  color: #1cbd69;
  font-size: 13px;
  font-weight: 500;
  border: 1px solid #13bd72;
  padding: 6px 30px;
  margin: 0 20px 20px 0;
  display: inline-block;
  border-radius: 50px;
  text-decoration: none;
  transition: 0.5s;
  background: transparent;
  font-family: "MontserratMedium";
}

.tags_main > button:hover {
  color: #fff;
  background-color: #1cbd69;
}

.tags_main button.selected {
  color: #fff;
  background-color: #1cbd69;
}

.new_recipe_page .input_drop {
  margin-bottom: 40px !important;
}

.select_caret_adjust.category_select_box {
  margin-bottom: 0 !important;
}

.select_caret_adjust {
  margin-bottom: 10px;
}

.contributor_upload input[type="submit"] {
  background: #eb6c19;
  border: 1px solid #eb6c19;
  padding: 5px 28px;
  border-radius: 5px;
  color: #fff;
}

form.contributor_upload {
  display: flex;
  align-items: center;
}

.contributor_upload input[type="submit"]:hover {
  transition: 0.2s ease;
  background: white;
  color: #9fc849;
}

.form textarea:focus-visible {
  border: 0;
  outline: none;
}

.w-20 {
  width: 20% !important;
}

.new_recipe_page .recipe_input input::placeholder,
.new_recipe_page .text_area textarea::placeholder {
  color: #9e9e9e !important;
  font-family: "MontserratLight";
}

.input_drop.border_bottom {
  padding-bottom: 40px !important;
  margin-bottom: 40px !important;
}

.border_bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 60px;
}

.new_recipe_page .text_area textarea {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 1%;
  outline: 0;
  font-size: 14px;
  color: #4a4a4a;
  resize: none;
  background: 0 0;
  /* height: 110px !important; */
  font-family: "MontserratLight";
}

.new_recipe_page .recipe_input i {
  position: absolute;
  right: 0;
  top: 2px;
  color: #eb6c19 !important;
  font-size: 20px;
}

.ing_recipe_input input {
  color: #ee8738 !important;
}

.ing_three_main i.fa-regular,
.ing_three_main i.fa {
  margin-left: 10px;
  height: 37px;
  width: 37px;
  color: #4a4a4a;
  border-radius: 12px;
  flex-shrink: 0 !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.new_recipe_page .text_area textarea.error {
  border: 1px solid #dd2c00 !important;
  border-radius: 3px;
  padding: 1%;
  outline: 0;
  font-size: 14px;
  color: #4a4a4a;
  resize: none;
  background: 0 0;
  height: 120px;
}

.text_area textarea {
  width: 100%;
  padding-right: 60px;
  border: 0;
  border-bottom: 1px solid #000;
}

.arrow_page button {
  border: 0;
  background: #fff;
  font-family: monospace;
  font-size: 18px;
  font-weight: 500;
}

.textarea_adjust .content-name {
  bottom: inherit !important;
  top: -5px;
}

.info_upload {
  color: #6c6c6c;
  font-size: 12px;
  font-weight: 500;
  font-family: "MontserratRegular";
}

.info_upload_desc {
  color: #6c6c6c;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.6rem;
  font-family: "MontserratRegular";
}

upload_photos .upload_boxes {
  margin-bottom: 5px !important;
}

.upload_boxes {
  width: 100% !important;
  display: flex !important;
  flex-wrap: wrap !important;
  margin: 0 0 25px !important;
}

.upload_boxes p {
  font-size: 13px;
  font-family: "MontserratRegular";
  margin-top: 10px;
  color: #eb6c19;
}

.upload_box i,
.upload_box .plus_photo_icon {
  object-fit: contain;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 35px;
  color: #6e6e6e;
  cursor: pointer;
}

.upload_box.thumbnailImage {
  width: 200px;
  min-width: 200px;
}

.m-50 {
  margin-right: 50px !important;
}

.upload_box {
  /* flex: 1 1; */
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eee;
  width: 175px;
  /* min-width: 300px; */
  height: 175px;
  margin-bottom: 5px;
  position: relative;
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
}

.upload_box input {
  background: #eeeeee;
  height: 175px;
  width: 100%;
  position: relative;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
}

.upload_box:not(:last-child) {
  margin-right: 25px;
}

.borderRedClass {
  border: 1px solid #dd2c00 !important;
}

.crossIcon {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  outline: 0;
  z-index: 999;
}

.primaryLabelIcon {
  position: absolute;
  bottom: 15px;
  right: 15px;
  cursor: default;
}

.video_url_adjust {
  width: 60%;
}

.video_url_adjustment {
  display: flex;
  align-items: baseline;
}

button.video_btn_url,
button.section_add {
  margin-left: 25px;
  background: #9fc849 !important;
  border: 1px solid #9fc849 !important;
  padding: 5px 28px;
  border-radius: 2px;
  color: #fff !important;
}

.ingrediants_main .section_add {
  margin-left: 25px;
  background: #13bd72 !important;
  border: 1px solid #13bd72 !important;
  padding: 7px 18px;
  border-radius: 2px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  color: #fff !important;
}

.ingrediants_main .recipe_input input {
  font-size: 14px !important;
  padding-left: 0;
  border-color: #eb6c19 !important;
}

.first_qty .label_main.ing_adjust {
  margin-top: 20px !important;
  padding-left: 30px !important;
  padding-bottom: 5px !important;
  color: #6c6c6c !important;
}

button.video_btn_url:hover,
button.section_add {
  transition: 0.2s ease;
  background: white;
  color: #9fc849;
}

.ing_three_main {
  margin-bottom: 10px;
}

.ingrediants_main .ing_three_main .first_qty input {
  font-size: 14px;
  box-sizing: border-box;
  /* border: none !important; */
  outline: none;
  background: 0 0;
  padding: 0 0 0 5px;
  line-height: 40px;
  height: 40px;
  width: 100%;
  background-color: #fff !important;
  /* box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%) !important; */
  border-radius: 0px !important;
}

.ingrediants_main p.instructions_counter {
  position: absolute;
  left: -15%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: #eb6c19;
  color: #fff;
  font-size: 14px;
  font-family: MontserratSemiBold;
  border-radius: 100%;
  margin-top: 15px;
}

/* .MuiFormControl-root {
    margin: 0 !important;
    width: 100%;
}

.MuiInputBase-formControl {
    margin: 0 !important;
}

.MuiInputLabel-standard {
    transform: translate(0, 6px) scale(1) !important;

}*/

/* select animation css of ingredient modal */
.right_main_pop .ingredient_input {
  margin: 13px 0 0;
}

.right_main_pop .ingredient_input .MuiFormControl-root {
  margin: 13px 0 0;
  height: 48px !important;
  justify-content: flex-end;
}

.ingredient_input label {
  font-size: 14px;
}

.ingredient_input label.Mui-focused {
  color: #eb6c19 !important;
}

.Mui-error.MuiInputBase-formControl::before {
  border-bottom: 1px solid rgb(221, 44, 0) !important;
}

.MuiInputBase-formControl::before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.MuiInputBase-formControl:hover::before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}

.ingredient_input .MuiInputBase-formControl:after {
  border-color: #eb6c19 !important;
}

.ingredient_input .MuiFormControl-root {
  margin: 0px 0 0 0 !important;
  width: 100%;
}

.input_drop.d-flex.align-items-end .label_main {
  margin-bottom: 6px !important;
}

/* select animation css of recipe modal */
.MuiPaper-root {
  border-radius: 0 !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12) !important;
}

.select_caret_adjust .MuiFormControl-root {
  width: 75%;
  height: 48px !important;
  justify-content: flex-end;
  font-family: "MontserratLight";
}

.MuiFormControl-root label {
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.38);
  font-family: "MontserratLight";
}

div#demo-simple-select-error {
  font-family: "MontserratLight";
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.38) !important;
}

.MuiMenu-list {
  max-height: 256px;
  min-height: 48px;
  overflow: auto;
  color: rgba(33, 33, 33, 0.87) !important;
}

.MuiMenu-list li {
  padding: 15px !important;
  font-size: 14px !important;
  color: rgba(33, 33, 33, 0.87) !important;
  font-family: "RobotoDraft", "Roboto", "Helvetica Neue", sans-serif;
}

.Mui-error.Mui-focused,
.Mui-error {
  color: rgb(221, 44, 0) !important;
}

.Mui-focused {
  color: #000 !important;
}

.MuiFormControl-root {
  margin: -16px 0 0 0 !important;
  width: 100%;
}

.Mui-error.MuiInputBase-formControl:after,
.Mui-error.Mui-focused.MuiInputBase-formControl:after {
  border-color: rgb(221, 44, 0) !important;
}

.MuiInputBase-formControl:after {
  border-color: #909090 !important;
}

.MuiInput-input {
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 14px !important;
}

.MuiInput-input:focus {
  color: rgb(0 0 0, 0.87) !important;
  background: none !important;
  background-color: transparent !important;
}

/* .Mui-focused {
    color: #909090 !important; 
} */

.ing_three_main .first_qty input {
  height: 35px !important;
  width: 110px !important;
  text-align: center;
  border: 1px solid #6e6e6e !important;
  margin: 0;
  border-radius: 4px !important;
}

.section_add {
  margin-left: 0 !important;
  margin-top: 25px;
}

.select_caret_adjust select {
  background-color: transparent;
}

.select_caret_adjust.third_qty select {
  font-size: 14px;
  box-sizing: border-box;
  border: none;
  box-shadow: none;
  outline: none;
  background: 0 0;
  padding: 0 15px;
  line-height: 40px;
  height: 40px;
  width: 100%;
  background-color: #fff !important;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%) !important;
}

.Mui-selected {
  background-color: rgb(238, 238, 238) !important;
}

.width-auto {
  width: max-content !important;
}

.new_recipe_page .recipe_input span.limit {
  top: 15px;
  right: 0;
}

.new_recipe_page .recipe_input,
.new_recipe_page .text_area,
.new_recipe_page .input_drop,
.new_recipe_page .upload_photos,
.new_recipe_page > hr,
.new_recipe_page .video_url_adjustment,
.new_recipe_page .ingrediants_main {
  margin-bottom: 30px;
}

.new_recipe_page .ingrediants_main {
  justify-content: flex-end;
}

.new_recipe_page .input_drop {
  border-color: rgba(0, 0, 0, 0.2) !important;
}

.new_recipe_page .input_drop:focus {
  border-color: #000 !important;
}

.video_url_adjustment .video_btn_url {
  color: rgba(0, 0, 0, 0.38) !important;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  padding: 9px 28px;
  border: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.video_url_adjustment .enable {
  color: rgb(255, 255, 255) !important;
  background-color: #13bd72 !important;
}

.ingrediants_main_space {
  margin-bottom: 60px !important;
}

.ingrediants_main.d-flex.flex-wrap.ingrediants_main_space.align-items-start.border_bottom {
  padding-bottom: 0 !important;
  margin-bottom: 10px !important;
}

.new_recipe_page .ingrediants_main .recipe_input input::placeholder {
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
}

.new_recipe_page .ingrediants_main .recipe_input input {
  /* color: #eb6c19; */
  font-size: 14px;
  font-family: "MontserratMedium";
  color: #ee8738 !important;
  font-family: "RobotoDraft", "Roboto", "Helvetica Neue", sans-serif !important;
}

.new_recipe_page .recipe_input input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #eb6c19;
  width: 100%;
  border-radius: 0 !important;
  font-size: 20px;
  font-weight: 500;
  padding-left: 0 !important;
  padding-bottom: 0 !important;
}

.new_recipe_page .recipe_input input.error {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #dd2c00 !important;
  width: 100%;
  border-radius: 0 !important;
  font-size: 20px;
  font-weight: 500;
}

.new_recipe_page .recipe_input input.error::placeholder {
  color: #dd2c00 !important;
  font-weight: 600;
}

.video_url_adjustment .bg_remove:focus {
  border-color: #ee8738 !important;
}

.video_url_adjustment .bg_remove {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  width: 50%;
}

.video_url_adjustment .error {
  border: none;
  border-bottom: 1px solid #dd2c00;
  width: 50%;
  background: transparent;
}

.video_url_adjustment .errorText {
  color: rgb(255, 0, 0);
  margin-top: 5px;
  float: left;
  font-size: 14px;
}

.video_url_adjustment .bg_remove:focus {
  border-bottom: 1px solid rgba(0, 0, 0, 0.8);
}

.upload_photos_heading {
  padding-right: 40;
}

.new_recipe_page .label_main.ing_adjust {
  color: #eb6c19;
  font-size: 14px;
  font-family: "MontserratMedium";
  text-transform: uppercase;
  margin: 0 !important;
}

.label_main.ing_adjust {
  color: #000;
  margin-top: 25px !important;
}

.ing_three_main {
  display: flex;
  align-items: self-end;
  width: 70%;
}

.ing_three_main .first_qty {
  width: 80px;
  flex: inherit !important;
}

.ing_three_main .first_qty input {
  width: 100% !important;
}

.ing_three_main .first_qty .label_main.ing_adjust {
  padding: 0 !important;
  text-align: center !important;
  width: 100% !important;
}

.MuiFormControl-root.form-control {
  margin: 0 !important;
}

.first_qty {
  flex: 1;
  margin-right: 15px;
}

.first_qty input.error {
  flex: 1;
  margin-right: 25px;
  border: 1px solid #dd2c00 !important;
}

.ing_three_main .sec_qty {
  flex: inherit !important;
  width: 140px !important;
}

.sec_qty {
  flex: 3;
  margin-right: 15px;
}

.third_qty {
  flex: 8;
  font-size: 14px;
}

.ingrediants_main .click_to_add button {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.click_to_add button {
  width: 70%;
  background-color: #eee;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  color: #909090;
  font-size: 13px;
  cursor: pointer;
  text-indent: 10px;
  text-align: left;
  padding: 10px;
  font-weight: 600;
  font-family: "MontserratMedium";
}

.checkbox_container {
  margin: 25px 0;
  color: #eb6c19;
  font-weight: 500;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
}

.checkbox_container > label {
  display: inline-block;
}

button.done_main.disable {
  pointer-events: none !important;
  border: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.38) !important;
}

.checkbox_container label.form-check-label {
  font-size: 14px;
}

.checkbox_container input {
  margin-right: 10px;
}

.checkbox_container label span {
  font-weight: 700;
}

.check_box_sep:not(:last-child) {
  margin-bottom: 5px;
}

.done_main button {
  width: 170px;
  justify-content: center;
}

li.done_main button {
  margin: 0.25rem 1rem;
  background: #9fc849;
  border: 1px solid #9fc849;
  padding: 5px 28px;
  border-radius: 5px;
  color: #fff;
  margin-top: 5px;
}

.contributor.contributor_upload .submit {
  position: absolute;
  left: 0;
}

.contributor.contributor_upload {
  position: relative;
}

.contributor.contributor_upload input[type="file"] {
  opacity: 0;
  width: 118px;
  position: relative;
  z-index: 3;
}

li.done_main button:hover {
  transition: 0.2s ease;
  background: white;
  color: #9fc849;
}

.post_now_adjust ul {
  min-width: 230px;
  width: 100%;
}

/* Notification start*/
.notification-form {
  padding-top: 40px;
  /* padding-left: 15px;
    padding-right: 15px; */
}

.textarea-desc {
  border: 1px solid #ee8738 !important;
  border-radius: 3px;
  width: 94%;
  padding: 10px;
  outline: 0;
  resize: none;
  background: 0 0;
  color: #4a4a4a;
  font-size: 14px;
}

.mainButtonsDisabled {
  pointer-events: none !important;
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.38);
}

.notification-dialog .modal-dialog {
  max-width: 450px !important;
  width: 100%;
}

.btn_add_notification {
  display: flex;
  flex-direction: column;
}

.btn_add_notification button {
  margin-right: auto;
  padding: 10px;
}

/* Notification end*/

/* tab starts */

/* Style the tab */

.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */

.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */

.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */

.tab button.active {
  background-color: #9fc849;
  color: #fff;
}

/* Style the tab content */

.tabcontent {
  display: none;
  border: 1px solid #ccc;
  border-top: none;
}

/* tab ends */

.aisle_pop_adjust {
  width: 90%;
  margin: auto;
}

.pop_adjust {
  width: 90%;
  margin: 25px auto 0;
}

.upload_boxes.guide_adjust .upload_box {
  max-width: 300px;
  width: 100%;
}

body .mt-5 {
  margin-top: 25px !important;
}

.main_spacing.container {
  margin-left: 0;
}

.faq_container {
  background: white;
  padding: 15px;
  box-shadow: 0px 2px 5px #0000002b;
  margin-top: 25px;
}

.faq_container_Loader {
  background: white;
  box-shadow: 0px 2px 5px #0000002b;
  margin-top: 25px;
  min-height: 500px;
}

.faq_content button {
  width: 100%;
  color: #333;
  background-color: #ffffff;
  border-color: #ddd;
  font-weight: 500;
}

.faq_content button {
  text-align: left;
}

.faq_content {
  padding: 15px 25px;
  background: #f3f3f3;
  border: 1px dotted #eb6c19;
}

.faq_content .btn-check:focus + .btn-success,
.faq_content .btn-success:focus,
.faq_content .btn-success:hover {
  color: inherit;
  background-color: inherit;
  border-color: inherit;
  box-shadow: inherit;
}

.faq_content:not(:last-child) {
  margin-bottom: 25px;
}

.faq_all div {
  padding: 15px;
  background: white;
  border: 1px solid #ddd;
  border-top: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: 100ms cubic-bezier(0.4, 0, 1, 1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.faq_content {
  display: flex;
  align-items: baseline;
  /* take it */
  margin-bottom: 20px;
}

.faq_all {
  width: 100%;
}

.faq_action_icons {
  display: flex;
  align-items: center;
}

.faq_action_icons div {
  height: 30px;
  width: 30px;
  margin: 0 10px;
}

.faq_edit {
  background: #eb6c19;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 5px;
}

.faq_trash {
  background: #ff0000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 5px;
  margin: 0px !important;
}

.input_validation {
  text-align: left;
  display: block;
  width: 100%;
  color: #ff0000;
  font-size: 12px;
  transform: translateY(-10px);
}

.faq_action_icons a {
  text-decoration: none;
}

.faq_add_main {
  margin: 25px 0 15px;
}

.faq_main_btns {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.faq_add_main {
  margin: 25px 0 15px;
  background: #000000;
  border: 1px solid #000000;
  padding: 7px 28px;
  border-radius: 5px;
  color: #fff;
}

.faq_add_main:hover {
  background: #fff;
  color: #000;
  transition: 0.2s ease;
}

button.publish_faq {
  background: #13bd72;
  border: 1px solid #13bd72;
  padding: 7px 28px;
  border-radius: 5px;
  color: #fff;
}
button.publish_product {
  background: #13bd72;
  border: 1px solid #13bd72;
  padding: 7px 28px;
  border-radius: 5px;
  color: #fff;
}

.faq_all .answer_tag {
  padding: 2px 5px;
  font-size: 12px;
  background: #3c8dbc;
  color: #fff;
  font-weight: 600;
  border-radius: 4px;
  margin-bottom: 10px;
}

.modal-body textarea {
  width: 100%;
}

.faq_edit_adjust .modal-header {
  border-bottom: 1px solid #dee2e6;
}

.enter_email {
  font-weight: 600;
  font-size: 18px;
}

.action_icon i {
  font-size: 16px;
  color: #909090;
}

.action_icon i:hover {
  color: #333;
}

.disable i:hover {
  color: #909090;
}

.action_icon .feature {
  color: #fecd34 !important;
}

.livelbl {
  font-size: 13px;
  color: #1cbd69;
  font-weight: 400;
  font-family: MontserratRegular;
  letter-spacing: 0.01em;
  opacity: 0.3;
}

.lblDraftStatus,
.lblLiveStatus {
  opacity: 1 !important;
}

.draftlbl {
  font-size: 13px;
  color: #6c6c6c;
  font-weight: 400;
  font-family: MontserratRegular;
  letter-spacing: 0.01em;
  opacity: 0.3;
}

.enter_subemail {
  margin: 10px 0;
  font-size: 14px;
}

.search_bar {
  width: 100%;
  position: relative;
  justify-content: flex-end;
}

.search_bar input {
  width: 100%;
  /* max-width: none; */
  margin-left: 15px;
}

.search_bar i {
  /* position: absolute; */
  top: 4px;
  bottom: 0;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.4) !important;
  font-size: 14px;
}

.statusDropDown {
  margin-top: 50px;
}

.statusDropDown div#demo-simple-select-error {
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 16px !important;
  font-family: RobotoDraft, Roboto, "Helvetica Neue", sans-serif;
}

.MuiMenu-list li.statusDropDownOption {
  font-size: 16px !important;
  text-transform: capitalize;
  height: 48px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.MuiMenu-list {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.statusDropDown .MuiInputBase-formControl::before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5) !important;
}

.form_card {
  background: transparent;
  box-shadow: none;
  padding: 10px;
  margin-bottom: 25px;
}

.select_caret_adjust.recipe_adjust .only_search {
  width: 100%;
  padding: 0;
  margin-bottom: 40px;
}

.select_caret_adjust.recipe_adjust {
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.main_table.recipe_adjust .recipe_with_image img {
  margin-left: 10px;
}

.recipe_adjust .only_search {
  padding: 0 25px;
}

.toggle div {
  display: flex;
  /* padding-left: 0; */
  align-items: center;
  justify-content: space-between;
}

.form-switch {
  padding-left: 0px !important;
  max-width: 135px;
}

.toggle .form-switch .form-check-input {
  margin-top: 0;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.right_bar .new_rec_view_all {
  padding: 60px 30px 30px 30px;
  align-items: flex-end;
}

.select_caret_adjust.recipe_adjust .only_search input {
  max-width: 70%;
}

/* 404 starts */

.page_not_found {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.page_not_found img {
  width: 50%;
  height: auto;
  object-fit: contain;
}

.page_not_found button {
  margin-top: 50px;
}

.page_not_exist {
  padding: 50px 10px 0;
  font-size: 20px;
  font-weight: 500;
}

select {
  border-radius: 0 !important;
  -webkit-box-shadow: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.add_zoom_details .form-control {
  width: 75% !important;
}

.add_zoom_details .form-control > div {
  width: 100% !important;
  flex-direction: row-reverse;
  justify-content: flex-end;
  position: relative;
}

/* .add_zoom_details .form-control>div::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 90px;
    height: 10px;
    width: 10px;
    transform: translateY(-50%);
    border-top: 5px solid #6c6c6c;
    border-bottom: 5px solid transparent;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
} */

.ingredient_table .ingredients_name_select select {
  position: relative;
  -webkit-appearance: auto !important;
}

.category_select_box select {
  width: 100% !important;
}

.add_zoom_details .form-control > div input {
  margin-left: 30px !important;
  padding: 0 !important;
  outline: none !important;
}

.add_zoom_details .form-control > div button {
  padding: 0 !important;
}

.add_zoom_details .form-control > div fieldset {
  display: none;
}

/* recipe */
.form-select {
  width: auto;
}

li.done_main button:hover {
  transition: 0.2s ease;
  background: white;
  color: #9fc849;
}

.notification-dialog .post_now_adjust ul {
  position: absolute;
  left: 0;
  bottom: 70px;
  min-width: 370px;
  width: 100% !important;
}

.notification-dialog .post_now_adjust ul .schedule > li {
  width: 100% !important;
}

/* .notification-dialog .post_now_adjust ul .schedule>li .schedule_main input {
    width: 270px !important;
} */

/* .schedule>li .schedule_main input::-webkit-calendar-picker-indicator {
    display: none !important;
} */

.schedule > li .schedule_main input {
  position: relative !important;
  height: 30px !important;
  padding-left: 25px !important;
}

.schedule > li .schedule_main {
  position: relative !important;
}

.schedule > li .schedule_main svg {
  position: absolute;
  z-index: 999;
  left: 2px;
  top: 4px;
  pointer-events: none;
}

.schedule > li .schedule_main input::-webkit-calendar-picker-indicator {
  color: #eb6c19 !important;
  font-size: 20px !important;
  position: absolute !important;
  left: -10px !important;
  top: 0 !important;
  opacity: 0;
}

.post_now_adjust ul {
  min-width: 380px;
  padding: 20px 20px 20px 30px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #eb6c19;
  background-color: #fff;
  display: inline-block;
  margin-top: 10px;
  position: absolute;
  left: calc(100% + 10px);
  bottom: 0;
  margin-bottom: 0;
}

.myModaldelete_btn_width {
  width: 90%;
  margin: 40px auto 0;
}
ul.dropdown-menu.show {
  position: absolute;
  top: -180px;
  padding: 0;
}
ul.dropdown-menu.show li {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #000;
}
ul.dropdown-menu.show li:last-child {
  border-bottom: none;
}
ul.dropdown-menu.show li span {
  font-size: 14px;
}
ul.dropdown-menu.show li a {
  margin: 0;
  float: left;
  line-height: 25px;
  font-size: 14px;
  color: #909090;
}

.post_now_adjust .post_drop {
  position: relative;
  overflow: hidden;
  border: none;
  outline: none;
  background: transparent !important;
}

.post_now_btn.disable .post_drop i {
  color: rgba(0, 0, 0, 0.38) !important;
}

.post_now_btn .post_drop i {
  color: #fff;
}

.post_now_btn.disable .post_drop:after {
  border-left: 2px solid rgba(0, 0, 0, 0.38) !important;
}

.post_now_adjust .post_drop:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 80%;
  border-left: 2px solid #fff;
}

.checkbox_container .form-check {
  margin: 15px 0;
}

.btn-group.post_now_adjust {
  margin-top: 40px;
}

.prevImage {
  position: absolute;
  width: 100%;
  height: 174px;
  object-fit: cover;
}

/* schedule timer */
.schedule {
  border: 1px solid #dadada;
  display: flex;
  justify-content: space-between;
  /* margin: 0.25rem 1rem; */
  padding: 10px 5px;
  /* width: calc(100% - 2rem); */
  margin-bottom: 10px;
  align-items: center;
}

.schedule > div > div {
  border: none !important;
}

.schedule > div > div > div > div {
  border: none !important;
  font-family: "MontserratRegular";
  font-size: 14px;
  color: #eb6c19;
  outline: 0;
  cursor: pointer;
}

.schedule > li {
  flex: inherit !important;
  width: calc(100% - 50px) !important;
  display: inline-flex !important;
  align-items: center !important;
}

.schedule .form-control > div {
  flex-direction: row-reverse;
  justify-content: flex-end;
  padding: 0 !important;
}

.custom_swich_box .switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 14px;
  margin: 0 6px;
  margin-left: 20px;
  margin-right: 20px;
}

.custom_swich_box .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom_swich_box .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(158, 158, 158);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.custom_swich_box .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: -1px;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgb(250, 250, 250);
  transition: 0.4s;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.custom_swich_box input:checked + .slider {
  background-color: rgba(255, 215, 64, 0.5);
}

.custom_swich_box input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.custom_swich_box input:checked + .slider:before {
  background-color: rgb(255, 215, 64);
  -webkit-transform: translateY(-50%) translateX(16px);
  -ms-transform: translateY(-50%) translateX(16px);
  transform: translateY(-50%) translateX(16px);
}

.custom_swich_box .slider.round {
  border-radius: 34px;
}

.custom_swich_box .slider.round:before {
  border-radius: 50%;
}

.checkbox_label span {
  font-family: "MontserratSemiBold";
}

.checkbox_label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "MontserratRegular";
}

.checkbox_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox_mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 2px solid rgba(0, 0, 0, 0.54);
  border-radius: 2px;
}

.checkbox_label input:checked ~ .checkbox_mark {
  border: 2px solid #ee8738;
  background-color: #ee8738;
}

.checkbox_mark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox_label input:checked ~ .checkbox_mark:after {
  display: block;
}

.checkbox_label .checkbox_mark:after {
  left: 5px;
  top: 0px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.pagenum_content #react-select-3-listbox {
  min-width: unset !important;
  max-width: unset !important;
  width: 140px !important;
}

#react-select-3-listbox {
  min-width: 300px !important;
  max-width: 400px !important;
  width: 100% !important;
}

.schedule .form-control > div button {
  padding: 0 !important;
  display: inline-block;
  width: 22px !important;
  height: 22px !important;
  line-height: 20px !important;
  margin-bottom: 3px !important;
  color: #ee8738 !important;
  background: none !important;
  border-radius: 0 !important;
}

.schedule .form-control > div button svg,
.schedule .form-control > div button svg path {
  color: #ee8738 !important;
  fill: #ee8738 !important;
}

.schedule .form-control > div input {
  padding: 0 0 0 14px !important;
}

.schedule .form-control > div fieldset {
  display: none;
}

.schedule > div {
  flex: inherit !important;
  width: auto !important;
}

.post_now_btn .dropdown-toggle::after {
  display: none;
}

.post_now_btn ul button {
  width: 100% !important;
}

.post_now_btn > button {
  border: transparent !important;
  background-color: transparent !important;
}

.delete_disable {
  pointer-events: none !important;
}

.btn.disable {
  opacity: 1 !important;
  pointer-events: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.38) !important;
}

.MuiSvgIcon-root {
  height: 0.7em !important;
}

.btn-lbl {
  color: #909090 !important;
}

.btn-lbl:hover {
  color: #6c6c6c !important;
}

.btn-lbl.selected {
  color: #6c6c6c !important;
}

.pushbtn.disable {
  pointer-events: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.38) !important;
  border-radius: 0px;
  border: 0px;
  height: 36px;
  border-radius: 2px;
  line-height: 36px;
  font-size: 14px;
}

.post_now_btn.disable {
  pointer-events: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.38) !important;
}

.post_now_btn {
  width: 210px;
  height: 50px;
  color: #fff;
  background-color: #1cbd69 !important;
  border-radius: 5px;
  position: relative;
}

.btn-group.post_now_adjust .post_now_btn ul li button {
  font-size: 14px !important;
  color: #6c6c6c;
  text-transform: capitalize;
  height: auto !important;
  line-height: 14px;
  padding: 10px 0;
  /* width: auto !important;
        display: inline-block; */
}

.btn-group.post_now_adjust .post_now_btn ul li button.disable {
  color: #909090 !important;
}

.post_now_btn button {
  width: 155px;
  height: 50px;
  float: left;
  cursor: pointer;
  line-height: 50px;
  text-transform: uppercase;
  color: inherit;
  font-size: 15px;
  text-align: center;
  outline: 0;
  display: flex;
  justify-content: center;
  font-weight: 600;
}

.post_now_btn ul li button.active i {
  opacity: 1;
}

.post_now_btn ul li button i {
  /* opacity: 0; */
  margin-left: -22px;
  margin-right: 5px;
  font-size: 18px;
  color: #ee8738;
}

.post_now_btn ul li button {
  background: none;
  outline: none;
  border: none;
  color: #000;
  text-align: left;
  width: 100% !important;
  justify-content: flex-start;
}

.post_now_btn ul li {
  display: inline-block;
  width: 100%;
}

.post_now_btn .verticle_divider {
  width: 1px;
  height: 40px;
  margin-top: 5px;
  float: left;
  background-color: #fff;
  border: none;
}

.post_now_btn span {
  width: 50px;
  height: 50px;
  float: right;
  cursor: pointer;
  text-align: center;
  outline: 0;
}

.post_now_btn span i {
  color: #fff;
  font-size: 28px;
  margin-top: 10px;
  margin-left: -4px;
}

.showUl {
  position: absolute;
  inset: 0px auto auto 0px;
  margin-top: 40px !important;
  /* margin: 0px; */
  transform: translate(0px, 38px);
}

.post_now_adjust .post_now_adjust::after {
  position: absolute;
  z-index: 99;
  right: 12.5px !important;
}

.post_now_adjust .post_now_btn.disable span.dropdown-toggle i {
  color: inherit;
}

.post_now_adjust .post_now_btn.disable span.dropdown-toggle::before {
  border-color: inherit;
}

.post_now_adjust span.post_now_adjust::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 70%;
  width: 36px;
  background-color: transparent;
  border-left: 2px solid #fff;
}

.schedule > select {
  background: #fff;
  border: 0;
  margin-left: -10px;
  min-width: 60px;
  outline: none !important;
}

.schedule * {
  color: #545454 !important;
}

.schedule > li .schedule_main {
  border: 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 140px;
  font-size: 12px !important;
  line-height: 11px;
}

.schedule > li .schedule_main input {
  border: 0;
  padding-left: 5px;
  width: 140px;
  font-size: 12px !important;
  line-height: 11px;
}

.schedule,
.schedule .form-control > div input {
  font-size: 13px !important;
  line-height: 13px !important;
}

.schedule > * {
  flex: 1 1;
  width: 100%;
}

.add_zoom_details input[type="time"]:focus {
  outline: 1px solid #000 !important;
}

.add_zoom_details input[type="time"] {
  padding: 0px 4px !important;
  border: 1px solid rgba(0, 0, 0, 0.6) !important;
}
.export_recipes {
  margin: 20px 0px -75px 30px;
}
/* 404 ends */

.calendar_popup {
  color: #ee8738;
  font-size: 15px;
  text-align: center;
  margin-top: 30px;
}

.calendar_select {
  color: #909090 !important;
  font-size: 14px;
  margin-top: 30px;
}

.calendar_selectedDays {
  background: #ee8738;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  color: #fff;
  font-family: MontserratRegular;
  font-size: 11px;
  text-align: center;
  line-height: 20px;
  position: absolute;
  top: -5px;
  left: -5px;
}

.select_day ul {
  width: 100%;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 !important;
}

.select_day ul li {
  margin: 0 5px;
}

.select_day ul li button {
  background-color: #fff;
  border: 1px solid #13bd72;
  color: #13bd72;
  outline: none;
  padding: 0px 30px;
  border-radius: 50px;
  font-family: SummerBold;
  font-size: 30px !important;
  margin: 5px 0;
}

.select_day ul li button.selected {
  background-color: #13bd72 !important;
  color: #fff !important;
}

.video_url_adjustment .d-flex {
  flex-wrap: wrap;
}

.video_url_adjustment .d-flex input::placeholder {
  color: rgba(0, 0, 0, 0.4) !important;
}

.video_url_adjustment .d-flex input {
  order: 1;
  font-size: 15px;
}

.video_url_adjustment.d-flex.align-items-start.flex-wrap.border_bottom {
  margin-bottom: 60px;
}

.video_url_adjustment .d-flex .video_btn_url {
  order: 2;
}

.video_url_adjustment .d-flex .errorText {
  order: 3;
  margin-top: 0;
  width: 100%;
}

/* media starts  */

@media (max-width: 1200px) {
  .first_qty,
  .sec_qty {
    margin-right: 15px;
  }
}

@media (max-width: 960px) {
  .user_card {
    max-width: 100%;
  }

  .main_table table thead,
  .main_table table tbody {
    width: 100%;
    display: inline-table;
  }

  /*  toggle */
  #myLinks {
    display: none;
  }

  .topnav a {
    color: white;
    padding: 4px 16px;
    text-decoration: none;
    font-size: 17px;
    display: block;
  }

  .topnav a.icon {
    background: rgb(250, 96, 0);
    display: flex;
    position: fixed;
    right: 10px;
    top: 10px;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    z-index: 9999;
  }

  .topnav a.icon span {
    color: white;
    margin-bottom: 0;
    line-height: 3px;
    background: white;
    width: 18px;
    height: 3px;
  }

  .topnav a.icon span:not(:last-child) {
    margin-bottom: 3px;
  }

  ul#myLinks {
    position: absolute;
    top: 130px;
    z-index: 9;
    background: #fff;
    width: 100%;
    box-shadow: 0 2px 5px #a1a1a1;
  }

  /*  toggle ends */
  header {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }

  .main_title {
    display: none;
  }

  .menu-block {
    display: block;
  }

  .menu-none {
    display: none;
  }

  .left_bar {
    position: inherit;
  }

  .left_bar {
    max-width: none;
    width: inherit;
    min-height: auto;
    border-right: 0;
  }

  .left_bar ul li {
    padding: 0;
  }

  .left_bar ul li {
    height: auto;
  }

  .user_live,
  .right_header,
  p.breadcrumb_name {
    width: 100%;
    justify-content: space-between;
  }

  .main_part {
    margin-top: 130px;
  }
}

@media (max-width: 800px) {
  .ing_three_main,
  .click_to_add button {
    width: 100%;
  }

  .main_table table {
    overflow-x: scroll;
    width: 100%;
    display: block;
  }
}

@media screen and (min-device-width: 601px) and (max-device-width: 800px) {
  .recipe_with_image {
    align-items: flex-start;
    flex-direction: column;
  }
}

@media (max-width: 600px) {
  .main_logo {
    max-width: 170px;
  }

  .login_container,
  .login_page_adjust > p {
    width: 90%;
    max-width: inherit;
  }

  p.breadcrumb_name {
    font-size: 25px;
    margin-right: 10px;
  }

  .recipe_adjust .only_search {
    padding: 0 25px;
    margin: 10px 0;
  }

  .recipe_adjust select {
    margin: 0 15px;
  }

  .recipe_main_adjust {
    flex-direction: column;
  }

  .recipe_adjust {
    flex-wrap: wrap;
  }

  .faq_content {
    flex-direction: column;
    padding: 10px;
  }

  .faq_action_icons {
    margin-top: 10px;
  }

  .faq_content:not(:last-child) {
    margin-bottom: 10px;
  }

  .faq_container {
    padding: 0;
  }

  .right_header {
    margin-top: 15px;
    border-top: 1px solid #d5d5d5;
  }

  .upload_box input {
    height: auto;
  }

  .main_spacing {
    padding: 0 10px;
  }

  button.add_button {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .upload_boxes {
    width: 100%;
    flex-direction: column;
  }

  .upload_box {
    padding: 50px 10px;
  }

  .upload_box img {
    width: 40px;
  }

  .upload_box:not(:last-child) {
    margin-bottom: 25px;
    margin-right: 0px;
  }

  .recipe_with_image {
    display: flex;
    min-width: 260px !important;
    align-items: center;
  }

  body .table > :not(caption) > * > * {
    min-width: 150px;
  }

  .main_table th img {
    height: 17px;
    margin-left: 5px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }

  header {
    flex-direction: column;
    height: auto;
    align-items: flex-start;
  }

  .main_part {
    flex-direction: column;
  }

  .add_search {
    padding: 15px 10px;
    flex-wrap: wrap;
  }

  .main_table {
    margin: 10px;
  }

  .ing_main_pop {
    grid-template-columns: 1fr;
  }

  .main_title {
    margin-right: 10px;
  }

  .right_header {
    padding: 10px;
  }

  .cancel_btn {
    padding: 15px 10px;
  }

  .only_search {
    width: 100%;
  }

  .page_not_found img {
    width: 70%;
  }

  .search_bar input {
    max-width: 100%;
  }

  .user_live {
    width: 100%;
  }

  .main_title {
    display: none;
  }

  .main_part {
    overflow: inherit !important;
  }

  .select_caret_adjust.recipe_adjust {
    width: 100% !important;
  }

  .select_caret_adjust.recipe_adjust .only_search input {
    max-width: 100%;
  }

  .new_rec_view_all.recipe_main_adjust > a {
    text-decoration: none;
    width: 100%;
    display: inline-block;
    text-align: center;
  }
}

.img-responsive {
  width: 100px;
  height: 100px;
}

.date_picker_promotion,
.time_picker_promotion > input {
  width: 100%;
  max-width: 200px;
}

.date_picker_promotion input {
  padding: 0.469rem 0.75rem !important;
}

.new_promotional_page span.limit {
  right: 21% !important;
  bottom: 10px;
}

.xAxis .recharts-layer text {
  font-size: 10px;
  transform: translateX(25px) translatey(15px);
}

.xAxis .recharts-layer:last-child text {
  transform: translateX(15px) translatey(15px);
}

.xAxis .recharts-layer:first-child text {
  transform: translateX(35px) translatey(15px);
}

.graph_dropdown {
  display: inline-flex;
  float: right;
  margin: 0 !important;
  margin-top: 30px !important;
}

.noDataBox {
  width: 100%;
  padding: 25px;
  background: white;
  border: 1px solid #d8d8d8;
  font-weight: 600;
}

.custom-tooltip {
  border-radius: 10px;
  box-shadow: 0px 0px 5px #747272;
  padding: 10px 8px 1px;
}

.drag_table .MuiLinearProgress-root {
  top: -30px;
}
.drag_table td.loader-td {
  padding: 0 !important;
}
.css-bsdfev-MuiLinearProgress-root {
  position: relative;
  overflow: hidden;
  display: block;
  height: 3px !important;
  z-index: 0;
  background-color: rgb(167, 202, 237);
  background-color: #fa6d32;
}
.drag_table table th:first-child,
.drag_table table td:first-child {
  width: 69px !important;
}
.filter_main > div:first-child {
  position: absolute !important;
  bottom: 0;
  left: -185px;
  display: inline-block;
}
.filter_main .css-1jllj6i-control {
  display: none !important;
}
.filter_main .css-11unzgr {
  max-height: 140px !important;
}
.filter_main button.css-1r4vtzz,
.filter_main button.css-48ayfv {
  font-weight: 500;
  background: #13bd72 !important;
  color: #fff !important;
  border-radius: 60px;
  width: 150px;
  padding-left: 25px;
  font-size: 15px;
  font-family: "MontserratMedium";
  min-height: 40px;
}
.filter_main .css-1vr111p-option,
.filter_main .css-1qprcsu-option {
  font-size: 14px !important;
  color: rgba(33, 33, 33, 0.87) !important;
  font-weight: 400;
  font-family: "RobotoDraft", "Roboto", "Helvetica Neue", sans-serif;
  min-width: 150px !important;
}
.filter_main .css-1gpjby2 {
  display: flex;
  margin: 0px 15px !important;
}
.filter_main .css-1v99tuv {
  overflow: initial;
  padding-left: 10px;
}
.category_tabs {
  width: calc(100% - 60px);
  margin: 30px 30px 0px;
  border-bottom: 1px solid #d9d9d9;
}
.category_table {
  width: calc(100% - 60px);
  margin: 0px 30px 30px;
  border: 1px solid #d9d9d9;
  border-top: none !important;
}
.category_tabs .nav-item .nav-link {
  font-size: 16px;
  margin-bottom: 0px;
  font-family: "MontserratRegular";
  color: #6c6c6c;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  /* border-bottom: 2px solid #fff !important; */
}
.category_tabs .nav-item.active .nav-link {
  /* border-bottom: 2px solid #fa6000 !important; */
  background: #fa6000;
  color: #fff;
}
.ytp-large-play-button {
  width: 30px !important;
  transform: translate(-50%, -50%) !important;
}
.main-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 20ch; /* Show first 10 characters */
}
